.gallery-wrapper {
    margin-bottom:30px;
}
.gallery-wrapper .tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}
@media screen and (min-width: 768px){
    .gallery-wrapper .tabs {
        flex-wrap: nowrap;
        margin-top: 0px;
    }
}

.gallery-wrapper .tabitem {
    border: 3px solid var(--main-color);
    cursor: pointer;
    height: 50px;
    text-align: center;
    padding: 12px 16px;
    background-color: var(--main-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.gallery-wrapper .tabitem.tabitem-inactive {
    opacity: 0.3;
    filter: grayscale(100%);
    border: 1px solid var(--black-color);
}

.gallery-wrapper .tabitem-title {
    color: var(--white-color);
    font-size: 16px;
}
.gallery-wrapper .tab-content {
    padding: 0 12px;
}
.gallery-wrapper .masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    gap: 12px;
}

.gallery-wrapper .image-item {
    position: relative;
    max-width: 370px;
    margin: 15px auto;
}
.gallery-wrapper .image-item img {
    width: 100%;
    height: auto;
    cursor: pointer;
}
.gallery-wrapper .year-section{
    display: block;
}
.gallery-wrapper .year-section h2 {
    font-size: 25px;
    font-weight: 600;
    margin: 15px 0 0 5px;
    padding: 10px;
    line-height: 30px;
    border: 2px dotted var(--dark-grey);
    color: var(--main-color);
    display: inline-grid;
    border-radius: 20%;
    background-color: white;
}
