@import '../../style/App.css';
.team-box {
	max-width: 370px;
	height: 420px;
	margin: 0 auto;
	margin-top: 20px;
	position: relative;
}
.team-box img {
	width: 100%;
	height: 100%;
	border-radius: 50% 50% 0 0;
}
.team-box .team-box-info {
	background-color: var(--main-color);
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: left;
	width: 80%;
	padding: 5px 20px;
}
.team-box .team-box-info p {
	color: var(--black-color);
}
.team-box .team-box-info p:nth-child(2) {
	color: var(--white-color);
}
