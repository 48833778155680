@import '../../../style/App.css';

#contact .contact-info {
  width: 100%;
  padding: 10px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
}
@media screen and (min-width: 468px) {
  #contact .contact-info {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 768px) {
  #contact .contact-info {
    width: 60%;
    flex-wrap: nowrap;
  }
}