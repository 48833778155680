@import '../../style/App.css';
.hero-content {
	width: 100%;
	padding: 30px 0;
}
.hero-content .hero-info {
	padding-top: 60px;
}
.hero-content .hero-info h1 {
	font-size: 40px;
	line-height: 50px;
}
.hero-content .hero-info p {
	padding: 20px 0;
}
.hero-content .hero-image {
	max-width: 570px;
	height: auto;
	float: right;
}
.hero-content .hero-image img{
	border-radius: 30%;
}
.hero-content .counter-box {
	margin: 0 0 40px;
}
.hero-content .counter-box .suffix-text {
	padding-left: 10px;
}
.hero-content .counter-box .prefix-text {
	padding-right: 10px;
}
@media screen and (max-width: 1080px) {
	.hero-content .hero-info {
		padding-top: 0px;
	}
	.hero-content .hero-image {
		float: none;
		margin: 0 auto;
		margin-top: 50px;
	}
}
@media screen and (max-width: 767px) {
	.hero-content .hero-info {
		text-align: center;
	}
	.hero-content .hero-info h1 {
		font-size: 30px;
		line-height: 40px;
	}
}