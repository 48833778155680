
.gallery-wrapper .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
}

.gallery-wrapper .modal-content {
    position: relative;
    width: 80%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallery-wrapper .modal-content .modal-image {
    width: auto;
    margin: auto;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}
.gallery-wrapper .modal-header {
    color: white;
    position:absolute;
    top: -55px;
    text-align: center;  /* This aligns text inside the header, if needed */
}
.gallery-wrapper .modal-header h2{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.gallery-wrapper .modal .close, .gallery-wrapper .modal .next, .gallery-wrapper .modal .prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--grey-color);
}

.gallery-wrapper .modal .close {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    padding: 8px;
}
.gallery-wrapper .modal .close img{
    width: 100%;
    margin: auto;
}

.gallery-wrapper .modal .prev {
    left: 10px;
}

.gallery-wrapper .modal .next {
    right: 10px;
}

.gallery-wrapper .modal .prev img, .gallery-wrapper .modal .next img{
    margin:auto;
    width: 100%;
}
.gallery-wrapper .modal .prev, .gallery-wrapper .modal .next{
    width: 40px;
    height: 40px;
    padding: 8px;
    line-height: 60px;
}
.gallery-wrapper .modal .prev:hover, .gallery-wrapper .modal .next:hover{
    background-color: var(--white-color);
}