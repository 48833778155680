@import '../../style/App.css';

#about {
  /* padding: 30px 0; */
}

@media screen and (max-width: 990px) {
  #about {
    text-align: center;
  }
}
#about .inner-wrapper {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
#about .img-wrapper {
  max-width: 30%;
  margin: 0 20px 20px 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0 0 10px 0;
  }
}
#about .cont-wrapper {
  max-width: 60%;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
}