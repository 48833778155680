@import '../../style/App.css';

#contact .small-button button {
	background-color: var(--main-color);
	border: 2px solid var(--main-color);
	padding: 15px 40px;
	cursor: pointer;
	font-size: 1.2rem;
	font-weight: 800;
	margin: 5px 0 30px 0;
	outline: none;
	color: white;
}
#contact .small-button button:hover {
	border: 2px solid var(--main-color);
	background-color: transparent;
	color:  var(--black-color);
}
#contact  input {
	background-color: var(--light-color);
	width: 100%;
	max-width: 570px;
	height: 45px;
	border: 0px;
	padding: 0 15px;
	font-size: 12px;
	border: 2px solid var(--grey-color);
	outline: none;
	margin: 5px 0;
}
#contact input:active, #contact input:focus {
	border: 2px solid var(--main-color);
}
#contact ::placeholder {
	color: var(--black-color);
	font-size: 12px;
}
#contact textarea {
	background-color: var(--light-color);
	width: 100%;
	max-width: 570px;
	border: 0px;
	padding: 15px;
	font-size: 12px;
	border: 2px solid var(--grey-color);
	outline: none;
	margin: 5px 0;
}
#contact textarea:active, textarea:focus {
	border: 2px solid var(--main-color);
}
#contact ::placeholder {
	color: var(--black-color);
	font-size: 12px;
}
#contact .sending-btn {
	width: 170px;
	background-color: var(--main-color);
	border: 1px solid var(--white-color);
	cursor: pointer;
	min-height: 52px;
	padding: 7px 0;
	margin: 0px;
	border-radius: 5px;
}
#contact .sending-btn div {
	margin: 0 auto;
	width: 30px;
	height: 30px;
}
#contact .sending-btn div .sbl-circ {
	color: var(--black-color);
	position: relative;
	display: inline-block;
	border: 5px solid;
	border-radius: 50%;
	border-top-color: transparent;
	animation: rotate 1s linear infinite;
}
@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
@media screen and (max-width: 768px) {
	#contact {
		text-align: center;
	}
	#contact .sending-btn {
		margin: 30px auto;
	}
}
#contact #contact-form {
	width: 100%;
	background-color: var(--dark-grey);
	z-index: 1;
	padding: 0 30px;
	text-align: center;
}

#contact .img-wrapper {
	display: none;
}

@media screen and (min-width: 1024px) {
	#contact #contact-form, #contact .img-wrapper {
		width: 50%;
	}
	#contact .img-wrapper {
		display:block;
		padding: 50px 0;
	}
	#contact #contact-form{
		text-align: left;
	}
}
#contact .form-wrapper {
	display: flex;
	padding: 30px;
	width: 100%;
}