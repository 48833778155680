@import '../../../style/App.css';

.big-title h1 {
  font-size: 30px;
  padding: 20px;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 700px) {
  .big-title h1 {
    width: auto;
    text-align: left;
    padding: 40px 40px 20px;
  }
}
@media screen and (min-width: 1024px) {
  .big-title h1 {
    padding: 50px 50px 25px;
  }
}