@import '../../../style/App.css';
.button .button-link, .button a.button-link {
	background-color: var(--main-color);
	border: 2px solid var(--main-color);
	padding: 15px 30px;
	cursor: pointer;
	font-size: 1.4rem;
	font-weight: 800;
	color: var(--white-color);
}

.button .button-link:hover {
	border: 2px solid var(--main-color);
	background-color: transparent;
	color: var(--black-color);
}