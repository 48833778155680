:root {
  --white-color: #fff;
  --black-color: #1e1e1e;
  --light-color: #fbfbfb;
  --grey-color: #dfdddd;
  --light-grey:#f4f4f3;
  --dark-grey: #cac7c5;
  --main-color: #ff5e00;
  --secondary-color: #009A44;
}
